<template>
  <div class="roles view">
    <app-header :title="__('view.auth.roles.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info">
        <div class="control-bar__info-title">{{__('common.total')}}:</div>
        <div class="control-bar__info-value">{{ pagination.total }}</div>
      </div>
      <text-field
          class="control-bar__search"
          v-model="searchTerm"
          :label="__('common.search_term')"
          max-length="50"
          :hide-details="true"
          :clearable="true"
          @keyup:enter="refreshDelayed(100)"
          @click:clear="refreshDelayed(100)"
      />
      <div class="control-bar__buttons">
        <div class="control-bar__button" :title="__('common.refresh')" @click="refresh" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="add"/>
        </div>
        <div class="control-bar__button" :title="__('common.add')" @click="addRole" v-slashes>
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg"
               alt="add"/>
        </div>
      </div>
    </div>
    <div class="scroll-horizontal-overflow">
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
      <data-table
          :records="roles"
          :fields="dataTableFields"
          :record-actions="recordActions"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :loading="loading"
          @editRole="editRole"
          @sort="sort"
      />
      <pagination
          class="mt"
          :count-pages="pagination.pages"
          :total-visible="10"
          :page="pagination.page"
          :disabled="loading"
          @paginate="paginate"
      />
    </div>
    <edit-role ref="editRole" @updated="refresh" @created="editRole"/>
  </div>
</template>

<script>
  import apiSecured from '../../api/secured';
  import DataTable from '../../components/DataTable';
  import AppHeader from '../../components/AppHeader';
  import EditRole from '../../components/Auth/EditRole';
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { paginationMixin } from "../../mixins/pagination";
  import { pageTitleMixin } from "../../mixins/pageTitle";

  export default {
    mixins: [notificationsMixin, localizationMixin, paginationMixin, pageTitleMixin],
    components: {
      appHeader: AppHeader,
      dataTable: DataTable,
      editRole: EditRole
    },
    data: () => ({
      loading: false,
      roles: [],
      dataTableFields: [],
      recordActions: [],
    }),
    methods: {
      refreshDelayed(delay) {
        setTimeout(this.refresh, delay);
      },
      refresh(callback) {
        this.loading = true;
        apiSecured.get('/identity/role', {
          params: this.getPaginationParams(),
        }).then(async (res) => {
          this.roles = res.data;
          await this.enrichRecords();
          this.updatePagination(res);
          this.loading = false;
          this.showInfoNotification('Roles have been reloaded');
          if (typeof callback === 'function') {
            callback();
          }
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      async enrichRecords() {
        const parentIds = this.roles.filter(record => record.parentId !== null).map(record => record.parentId);
        const primeIds = this.roles.filter(record => record.primeId !== null).map(record => record.primeId);
        const viceIds = this.roles.filter(record => record.viceId !== null).map(record => record.viceId);

        if (parentIds.length > 0) {
          await apiSecured.get(`/identity/role/(${parentIds.join(',')})`, {
            params: {
              fields: 'id,name'
            },
          }).then((res) => {
            this.roles = this.roles.map(record => {
              record.parent = res.data.find(resRecord => resRecord.id === record.parentId);
              return record;
            });
            this.showInfoNotification('Parent roles have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }

        if (primeIds.length > 0 || viceIds.length > 0) {
          await apiSecured.get(`/identity/user/(${[...primeIds, ...viceIds].join(',')})`, {
            params: {
              fields: 'id,userName'
            },
          }).then((res) => {
            this.roles = this.roles.map(record => {
              record.prime = res.data.find(resUser => resUser.id === record.primeId);
              return record;
            });
            this.roles = this.roles.map(record => {
              record.vice = res.data.find(resUser => resUser.id === record.viceId);
              return record;
            });
            this.showInfoNotification('Users have been reloaded');
          }).catch((error) => {
            this.showErrorNotifications(error)
          });
        }
      },
      addRole() {
        this.$refs.editRole.showCreate();
      },
      editRole(role) {
        this.$refs.editRole.showEdit(role);
      }
    },
    created() {
      this.setPageTitle(this.__('entity.common.roles'));
      this.dataTableFields = [
        { name: 'id', title: this.__('entity.common.id'), type: 'default' },
        { name: 'name', title: this.__('entity.common.name'), type: 'default' },
        { name: 'parent.name', title: this.__('entity.auth.role.parent'), type: 'ref', disableSorting: true },
        { name: 'prime.userName', title: this.__('entity.common.prime'), type: 'ref', disableSorting: true },
        { name: 'vice.userName', title: this.__('entity.common.vice'), type: 'ref', disableSorting: true },
        { name: 'gitlabGroupIds', title: 'Gitlab Group Ids', type: 'default' },
        { name: 'createdAt', title: this.__('entity.common.created_at'), type: 'datetime' },
        { name: 'updatedAt', title: this.__('entity.common.updated_at'), type: 'datetime' }
      ];
      this.recordActions = [
        { title: this.__('component.auth.edit_role.title'), icon: 'pencil', event: 'editRole' },
      ];
      this.refresh();
    }
  }
</script>
